import { template as template_e5b112680d144c11b8901e62d643c6d1 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_e5b112680d144c11b8901e62d643c6d1(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
