import { template as template_34fa0762e77541fcb492e5e11f194bcc } from "@ember/template-compiler";
const WelcomeHeader = template_34fa0762e77541fcb492e5e11f194bcc(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
